import {Input} from "@chakra-ui/input"
import {VStack} from "@chakra-ui/layout"
import {Button, Spinner, useBreakpointValue} from "@chakra-ui/react"
import {FormBox} from "components/auth-forms/form-box"
import {FormHeading} from "components/auth-forms/form-heading"
import {FormMessage} from "components/auth-forms/form-message"
import firebase from "firebase"
import {trackEvent} from "helpers/tracking"
import {useIsIphone} from "hooks-desktop/navigator"
import Link from "next/link"
import Router from "next/router"
import {FormEvent, useCallback, useEffect, useState} from "react"
import {useSignInWithEmailAndPassword} from "react-firebase-hooks/auth"

export const LoginBox = () => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  const [signIn, user, loading, error] = useSignInWithEmailAndPassword(
    firebase.auth(),
  )

  const isIphone = useIsIphone()

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        trackEvent("login")
        Router.push("/tasks")
      }
    })
  }, [])

  const handleSubmit = useCallback(
    (ev: FormEvent) => {
      ev.preventDefault()

      signIn(email, password)
    },
    [email, password],
  )

  const inputSize = useBreakpointValue(["lg", "md"])

  useEffect(() => {
    if (!loading && user) {
      Router.push("/tasks")
    }
  }, [loading, user])

  return (
    <FormBox>
      {isIphone ? (
        <>
          <FormHeading>Download TestFlight</FormHeading>

          <p className="text-center text-lg text-gray-500">
            FocusTask is now available on iPhone as a TestFlight build. Download
            it and continue there.
          </p>

          <Link href="https://focustask.app/testflight" passHref>
            <Button
              type="submit"
              size={inputSize}
              width="100%"
              colorScheme="blue"
            >
              Download TestFlight
            </Button>
          </Link>

          <Link href="/" passHref>
            <Button type="submit" size={inputSize} width="100%">
              Cancel
            </Button>
          </Link>
        </>
      ) : (
        <>
          <FormHeading>Log in to FocusTask</FormHeading>

          <form onSubmit={handleSubmit}>
            <VStack>
              {error ? (
                <FormMessage status="error">{error.message}</FormMessage>
              ) : null}

              <Input
                size={inputSize}
                placeholder="Enter your email"
                value={email}
                onChange={(ev) => setEmail(ev.target.value)}
                type="email"
                enterKeyHint="go"
                disabled={loading}
              />

              <Input
                size={inputSize}
                placeholder="Enter your password"
                type="password"
                value={password}
                onChange={(ev) => setPassword(ev.target.value)}
                enterKeyHint="go"
                disabled={loading}
              />

              <div className="pb-2">
                <Link href="/users/reset-password" passHref>
                  <a className="text-gray-500 transition-colors duration-75 hover:text-gray-400">
                    Reset password
                  </a>
                </Link>
              </div>

              <Button
                type="submit"
                size={inputSize}
                width="100%"
                colorScheme="blue"
                isDisabled={loading}
              >
                {loading ? <Spinner size="sm" mr={2} /> : null}
                Log in
              </Button>

              <Link href="/" passHref>
                <Button type="submit" size={inputSize} width="100%">
                  Cancel
                </Button>
              </Link>
            </VStack>
          </form>
        </>
      )}
    </FormBox>
  )
}
