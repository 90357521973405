import {authHeaders} from "plugins/firebase/helpers"

export const authGet = async (path: string) => {
  const headers = await authHeaders()
  if (!headers) {
    console.warn("cant get, missing auth headers", path)
    return
  }

  return await fetch(path, {headers, method: "GET"})
}

export const authPost = async (path: string, data: {[key: string]: any}) => {
  const headers = await authHeaders()
  if (!headers) {
    console.warn("cant post, missing auth headers", path)
    return
  }

  return await post(path, {headers, data})
}

export const post = async (
  path: string,
  {
    headers = {},
    data,
  }: {
    headers?: Record<string, string>
    data: Record<string, any>
  },
) => {
  return await fetch(path, {
    method: "POST",
    headers: {"Content-Type": "application/json", ...headers},
    body: JSON.stringify(data),
  })
}
