import {useBreakpointValue} from "@chakra-ui/react"
import {useTheme} from "next-themes"
import {createContext, FC, useContext, useMemo} from "react"

const ResponsiveContext = createContext<
  {mobile: boolean; dark: boolean} | undefined
>(undefined)

export const ResponsiveProvider: FC<{}> = ({children}) => {
  const dark = useTheme().resolvedTheme === "dark"
  const mobile = useBreakpointValue([true, false]) ?? false

  const value = useMemo(() => ({dark, mobile}), [dark, mobile])

  return (
    <ResponsiveContext.Provider value={value}>
      {children}
    </ResponsiveContext.Provider>
  )
}

export const useResponsive = () => {
  const value = useContext(ResponsiveContext)
  if (!value)
    throw new Error("useDark() must be used within a ResponsiveProvider")
  return value
}

export const useMobile = () => {
  const {mobile} = useResponsive()
  return mobile
}
