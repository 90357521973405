import {FC} from "react"

export const FormHeading: FC<{}> = ({children}) => {
  return (
    <h2
      className="
        text-center text-2xl font-bold 
        text-gray-700 dark:text-gray-300
        sm:text-xl sm:font-semibold
      "
    >
      {children}
    </h2>
  )
}
