import firebase from "firebase"

const wantsEmulator =
  process.env.NODE_ENV === "test" || process.env.NEXT_PUBLIC_EMULATOR

if (!firebase.apps.length) {
  if (wantsEmulator) {
    console.log(
      "%cSetting Firebase with local emulator",
      "background: blue; color: #fff",
    )
  }

  firebase.initializeApp(
    wantsEmulator
      ? {projectId: "demo", apiKey: "test"}
      : {
          apiKey: "AIzaSyBmlzoQED1FXOPHRpp82Rfr1dEa1kxSnwY",
          authDomain: "focustask-4ee9f.firebaseapp.com",
          projectId: "focustask-4ee9f",
          storageBucket: "focustask-4ee9f.appspot.com",
          messagingSenderId: "1056948416151",
          appId: "1:1056948416151:web:83b6ffff7fa3e65e7d03d2",
        },
  )

  const auth = firebase.auth()
  const firestore = firebase.firestore()

  if (wantsEmulator) {
    auth.useEmulator("http://localhost:9099")
    firestore.useEmulator("localhost", 8080)
  }

  const browser = typeof window != "undefined" && !!window.indexedDB

  if (browser) {
    const db = firebase.firestore()
    db.enablePersistence({synchronizeTabs: true})
  }
}

export const firestore = () => {
  return firebase.firestore()
}

export const auth = () => {
  return firebase.auth()
}

export type Transaction = firebase.firestore.Transaction
export type WriteBatch = firebase.firestore.WriteBatch
