import {LoginScreen} from "components/app/login-screen"
import Head from "next/head"
import {AppTheme} from "themes/app"

export default function Login() {
  return (
    <AppTheme>
      <Head>
        <title>FocusTask | Log in</title>
      </Head>

      <div style={{height: "100%"}} suppressHydrationWarning>
        {typeof window === "undefined" ? null : <LoginScreen />}
      </div>
    </AppTheme>
  )
}
