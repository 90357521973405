import {FC} from "react"

export const FormBox: FC<{}> = ({children}) => {
  return (
    <div
      className="
        flex w-full flex-col space-y-3 border border-gray-200
        bg-white p-4 py-8
        dark:border-gray-800 dark:bg-gray-700
        sm:w-[400px] sm:rounded-md sm:p-8
      "
    >
      {children}
    </div>
  )
}
