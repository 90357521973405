import {useEffect, useState} from "react"

export const useIsIphone = () => {
  const [isIphone, setIphone] = useState(false)

  useEffect(() => {
    const userAgent = navigator.userAgent
    const iphone = userAgent.includes("iPhone")
    setIphone(iphone)
  })

  return isIphone
}
