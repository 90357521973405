import {Alert, Text} from "@chakra-ui/react"
import {FC} from "react"

export const FormMessage: FC<{status: "error" | "success"}> = ({status, children}) => {
  const color = status === "error" ? "red" : "green"

  return (
    <Alert
      status={status}
      fontSize="sm"
      background={`${color}.50`}
      _dark={{bg: `${color}.900`}}
      borderRadius="md"
    >
      <Text textColor={`${color}.700`} _dark={{textColor: `${color}.200`}}>
        {children}
      </Text>
    </Alert>
  )
}
