import {FormScreen} from "components/auth-forms/form-screen"
import {LoginBox} from "./login-box"

export const LoginScreen = () => {
  return (
    <FormScreen>
      <LoginBox />
    </FormScreen>
  )
}
