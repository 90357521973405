import {VStack} from "@chakra-ui/react"
import {FC} from "react"

export const FormScreen: FC<{}> = ({children}) => {
  return (
    <VStack
      background="gray.50"
      _dark={{background: "gray.900"}}
      height="full"
      alignItems="center"
      justifyContent="center"
    >
      {children}
    </VStack>
  )
}
