import {ChakraProvider, extendTheme, ThemeConfig} from "@chakra-ui/react"
import {ResponsiveProvider} from "plugins-desktop/responsive-provider/responsive-provider"
import {FC} from "react"

const config: ThemeConfig = {
  initialColorMode: "light",
  useSystemColorMode: true,
}

const theme = extendTheme({
  config,
  styles: {
    global: {
      html: {
        fontSize: "14px",
      },
    },
  },
  fontSizes: {
    "2xl": "18px",
    xl: "17px",
    lg: "16px",
    md: "14px",
    sm: "13px",
    xs: "12px",
  },
  colors: {
    blue: {
      "50": "#e5f2ff",
      "100": "#cce4ff",
      "200": "#99caff",
      "300": "#66afff",
      "400": "#3395ff",
      "500": "#007aff",
      "600": "#0062cc",
      "700": "#004999",
      "800": "#003166",
      "900": "#001833",
    },
    neutral: {
      "50": "rgb(250 250 250)",
      "100": "rgb(245 245 245)",
      "200": "rgb(229 229 229)",
      "300": "rgb(212 212 212)",
      "400": "rgb(163 163 163)",
      "500": "rgb(115 115 115)",
      "600": "rgb(82 82 82)",
      "700": "rgb(64 64 64)",
      "800": "rgb(38 38 38)",
      "900": "rgb(23 23 23)",
    },
    zinc: {
      "50": "#fafafa",
      "100": "#f4f4f5",
      "150": "#ececee",
      "200": "#e4e4e7",
      "300": "#d4d4d8",
      "400": "#a1a1aa",
      "500": "#71717a",
      "600": "#52525b",
      "700": "#3f3f46",
      "750": "#333338",
      "800": "#27272a",
      "850": "#202023",
      "900": "#18181b",
    },
    stone: {
      "50": "rgb(250 250 249)",
      "100": "rgb(245 245 244)",
      "200": "rgb(231 229 228)",
      "300": "rgb(214 211 209)",
      "400": "rgb(168 162 158)",
      "500": "rgb(120 113 108)",
      "600": "rgb(87 83 78)",
      "700": "rgb(68 64 60)",
      "800": "rgb(41 37 36)",
      "900": "rgb(28 25 23)",
    },
    slate: {
      "50": "#f8fafc",
      "100": "#f1f5f9",
      "200": "#e2e8f0",
      "300": "#cbd5e1",
      "400": "#94a3b8",
      "500": "#64748b",
      "600": "#475569",
      "700": "#334155",
      "800": "#1e293b",
      "900": "#0f172a",
    },
    gray: {
      "50": "#f9fafb",
      "100": "#f3f4f6",
      "150": "#eceef1",
      "200": "#e5e7eb",
      "300": "#d1d5db",
      "400": "#9ca3af",
      "500": "#6b7280",
      "600": "#4b5563",
      "700": "#374151",
      "800": "#1f2937",
      "900": "#111827",
    },
  },
  components: {
    Button: {
      baseStyle: {
        _focus: {boxShadow: "none"},
      },
    },
  },
})

/**
 * @deprecated use Tailwind instead
 */
export const AppTheme: FC = ({children}) => {
  return (
    <ChakraProvider theme={theme}>
      <ResponsiveProvider>{children}</ResponsiveProvider>
    </ChakraProvider>
  )
}
