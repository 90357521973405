import {auth, firestore} from "platform/firebase"

export const userCollection = (uid: string, collection: string) => {
  return userDoc(uid).collection(collection)
}

export const userDoc = (uid: string) => {
  const db = firestore()
  return db.collection("users").doc(uid)
}

export const authHeaders = async () => {
  const user = auth().currentUser

  if (!user) {
    return null
  }

  const idToken = await user.getIdToken()

  return {
    Authorization: `Firebase ${idToken}`,
  }
}
